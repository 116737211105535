// ** Initial State
const initialState = {
    nutritionsData: [],
    total: 1,
    totalItems: 0,
    params: {},
    selectedNutrition: null,
    errorMessage: null,
    status: null // 1 - fetching // 2 - success // 3 - error
  }
  
  const nutritions = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_NUTRITIONS':
        return {
          ...state,
          nutritionsData: action.data,
          total: action.totalPages,
          totalItems: action.totalItems,
          params: action.params
      }
      case 'GET_NUTRITION':
        return { ...state, selectedNutrition: action.selectedNutrition }
      case 'ADD_NUTRITION':
        return { ...state }
      case 'UPDATE_NUTRITION':
        return { ...state }
      case 'DELETE_NUTRITION':
        return { ...state }
      case 'NUTRITIONS_ERROR':
        return { ...state, errorMessage: action.error }
      case 'SET_NUTRITIONS_STATUS':
        return { ...state, status: action.status }
      default:
        return { ...state }
    }
  }
  export default nutritions
  