// ** Initial State
const initialState = {
    allCurrency: [],
    totalItems: 0,
    detailCurrency: [],
    status: null, // 1 - fetching // 2 - success // 3 - error
    errorMessage: null
  }
  
  const currencies = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_CURRENCY':
        return { ...state, allCurrency: action.data, totalItems: action.totalItems }
      case 'GET_PET_BREEDS':
        return {
          ...state,
          petBreedData: action.petBreedData,
          total: action.totalPages,
          totalItems: action.totalItems,
          params: action.params
      }
      case 'GET_DETAIL_CURRENCY':
        return { ...state, detailCurrency: action.detailCurrency }
      case 'ADD_CURRENCY':
        return { ...state }
      case 'UPDATE_CURRENCY':
        return { ...state }
      case 'DELETE_CURRENCY':
        return { ...state }
      case 'CURRENCY_ERROR':
        return { ...state, errorMessage: action.error }
      case 'SET_CURRENCY_STATUS':
        return { ...state, status: action.status }
      default:
        return { ...state }
    }
  }
  export default currencies
  