// ** Initial State
const initialState = {
    allDevices: [],
    devicesData: [],
    total: 1,
    totalItems: 0,
    params: {},
    selectedDevice: null,
    errorMessage: null,
    status: null, // 1 - fetching // 2 - success // 3 - error
    allDevicePets: [],
    petsErrorMessage: null,
    devicePetStatus: null
  }
  
  const devices = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_DEVICES':
        return { ...state, allDevices: action.data }
      case 'GET_DEVICES':
        return {
          ...state,
          devicesData: action.data,
          total: action.totalPages,
          totalItems: action.totalItems,
          params: action.params
      }
      case 'GET_DEVICE':
        return { ...state, selectedDevice: action.selectedDevice }
      case 'ADD_DEVICE':
        return { ...state }
      case 'UPDATE_DEVICE':
        return { ...state }
      case 'DELETE_DEVICE':
        return { ...state }
      case 'DEVICE_ERROR':
        return { ...state, errorMessage: action.error }
      case 'SET_DEVICE_STATUS':
        return { ...state, status: action.status }
      case 'GET_DEVICE_PETS':
        return { ...state, allDevicePets: action.pets }
      case 'DEVICE_PETS_ERROR':
        return { ...state, petsErrorMessage: action.petsError }
      case 'SET_DEVICE_PETS_STATUS':
        return { ...state, devicePetStatus: action.petsStatus }
      default:
        return { ...state }
    }
  }
  export default devices
  