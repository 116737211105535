// ** Initial State
const initialState = {
    allPromotions: [],
    promotionsData: [],
    total: 1,
    totalItems: 0,
    params: {},
    selectedPromotion: null,
    errorMessage: null,
    status: null, // 1 - fetching // 2 - success // 3 - error
    promotionImageUrl: null
  }
  
  const promotions = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_PROMOTIONS':
        return { ...state, allPromotions: action.data, totalItems: action.totalItems }
      case 'GET_PROMOTIONS':
        return {
          ...state,
          promotionsData: action.promotionsData,
          total: action.totalPages,
          totalItems: action.totalItems,
          params: action.params
      }
      case 'GET_PROMOTION':
        return { ...state, selectedPromotion: action.selectedPromotion }
      case 'ADD_PROMOTION':
        return { ...state }
      case 'UPDATE_PROMOTION':
        return { ...state }
      case 'DELETE_PROMOTION':
        return { ...state }
      case 'PROMOTION_ERROR':
        return { ...state, errorMessage: action.error }
      case 'SET_PROMOTION_STATUS':
        return { ...state, status: action.status }
      case 'UPLOAD_PROMOTION_IMAGE':
        return { ...state, promotionImageUrl: action.imageUrl }
      default:
        return { ...state }
    }
  }
  export default promotions
  