// ** Initial State
const initialState = {
    allPetBreed: [],
    petBreedData: [],
    total: 1,
    totalItems: 0,
    params: {},
    selectedPetBreed: null,
    errorMessage: null,
    status: null // 1 - fetching // 2 - success // 3 - error
  }
  
  const petBreed = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_PET_BREED':
        return { ...state, allPetBreed: action.data, totalItems: action.totalItems }
      case 'GET_PET_BREEDS':
        return {
          ...state,
          petBreedData: action.petBreedData,
          total: action.totalPages,
          totalItems: action.totalItems,
          params: action.params
      }
      case 'GET_PET_BREED':
        return { ...state, selectedPetBreed: action.selectedPetBreed }
      case 'ADD_PET_BREED':
        return { ...state }
      case 'UPDATE_PET_BREED':
        return { ...state }
      case 'DELETE_PET_BREED':
        return { ...state }
      case 'PET_BREED_ERROR':
        return { ...state, errorMessage: action.error }
      case 'SET_PET_BREED_STATUS':
        return { ...state, status: action.status }
      default:
        return { ...state }
    }
  }
  export default petBreed
  