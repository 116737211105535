// ** Initial State
const initialState = {
  deviceVersionsData: [],
  selectedDeviceVersion: null,
  total: 1,
  totalItems: 0,
  params: {},
  errorMessage: null,
  status: null // 1 - fetching // 2 - success // 3 - error
}

const deviceVersions = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DEVICE_VERSIONS':
      return {
        ...state,
        deviceVersionsData: action.data,
        total: action.totalPages,
        totalItems: action.totalItems,
        params: action.params
    }
    case 'GET_DEVICE_VERSION':
      return { ...state, selectedDeviceVersion: action.selectedDeviceVersion }
    case 'ADD_DEVICE_VERSION':
      return { ...state }
      case 'DELETE_DEVICE_VERSION':
      return { ...state }
    case 'DEVICE_VERSIONS_ERROR':
      return { ...state, errorMessage: action.error }
    case 'SET_DEVICE_VERSIONS_STATUS':
      return { ...state, status: action.status }
    case 'UPDATE_DEVICE_VERSION':
      return { ...state, status: action.status }
    default:
      return { ...state }
  }
}
export default deviceVersions
