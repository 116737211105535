// **  Initial State
const initialState = {
  userData: {},
  userRole: null,
  loginStatus: null,
  error: null
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.data,
        userRole: action.userRole,
        [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }
    case 'SET_USER_DATA':
      return {
        ...state,
        userData: action.data
      }
    case 'LOGIN_ERROR':
      return {
        ...state,
        error: action.error
      }
    case 'LOGOUT':
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {}, ...obj }
    case 'REGISTER':
      return {
        ...state
        // userData: action.data
      }
    default:
      return state
  }
}

export default authReducer
