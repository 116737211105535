// ** Initial State
const initialState = {
    allServices: [],
    errorMessage: null,
    galleryErrorMessage: null,
    providersErrorMessage: null,
    provErrorMessage: null,
    data: [],
    total: 1,
    params: {},
    selectedService: null,
    serviceToEdit: null,
    selectedProvider: null,
    featuredProviders: [],
    status: null, // 1 - fetching // 2 - success // 3 - error
    providersStatus: null,
    provStatus: null,
    promotionImageUrl: null,
    providerImageUrl: null
  }
  
  const cms = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_SERVICES':
        return { ...state, allServices: action.data }
      case 'GET_DATA':
        return {
          ...state,
          data: action.data,
          total: action.totalItems,
          params: action.params
      }
      case 'GET_SERVICE':
        return { ...state, serviceToEdit: action.service }
      case 'GET_PROVIDERS':
        return { ...state, selectedService: action.providers }
      case 'GET_FEATURED':
        return { ...state, featuredProviders: action.featured, selectedService: [] }
      case 'GET_PROVIDER':
        return { ...state, selectedProvider: action.provider }
      case 'ADD_SERVICE':
        return { ...state }
      case 'UPDATE_SERVICE':
        return { ...state }
      case 'DELETE_SERVICE':
        return { ...state }
      case 'ADD_PROVIDER':
        return { ...state }
      case 'UPDATE_PROVIDER':
        return { ...state }
      case 'DELETE_PROVIDER':
        return { ...state }
      case 'ITEM_ERROR':
        return { ...state, errorMessage: action.error }
      case 'GALLERY_ERROR':
        return { ...state, galleryErrorMessage: action.error }
      case 'SET_ITEM_STATUS':
        return { ...state, status: action.status }
      case 'PROVIDERS_ERROR':
        return { ...state, providersErrorMessage: action.error }
      case 'PROVIDERS_STATUS':
        return { ...state, providersStatus: action.status }
      case 'PROV_ERROR':
        return { ...state, provErrorMessage: action.error }
      case 'PROV_STATUS':
        return { ...state, provStatus: action.status }
      case 'UPLOAD_SERVICE_ICON':
        return { ...state, serviceIconUrl: action.iconUrl }
      case 'UPLOAD_PROVIDER_LOGO':
        return { ...state, providerImageUrl: action.image }
      case 'UPLOAD_GALLERY':
        return { ...state, providerGallery: action.gallery }
      default:
        return { ...state }
    }
  }
  export default cms
  