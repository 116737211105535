// ** Initial State
const initialState = {
  contentPages: [],
  selectedPage: null,
  errorMessage: null,
  status: null, // 1 - fetching // 2 - success // 3 - error
  pageError: null,
  pageStatus: null,
  imageUrl: '',
  iconUrl: ''
}

const contentPages = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CONTENT_PAGES':
      return { ...state, contentPages: action.pages }
    case 'GET_CONTENT_PAGE':
      return { ...state, selectedPage: action.page }
    case 'ADD_IMAGE':
      return { ...state, imageUrl: action.imageUrl }
    case 'ADD_ICON':
      return { ...state, iconUrl: action.iconUrl }
    case 'ADD_CONTENT_PAGE':
      return { ...state }
    case 'UPDATE_CONTENT_PAGE':
      return { ...state }
    case 'DELETE_CONTENT_PAGE':
      return { ...state }
    case 'ITEM_ERROR':
      return { ...state, errorMessage: action.error }
    case 'SET_ITEM_STATUS':
      return { ...state, status: action.status }
    case 'PAGE_ERROR':
      return { ...state, pageError: action.error }
    case 'SET_PAGE_STATUS':
      return { ...state, pageStatus: action.status }
    default:
      return { ...state }
  }
}
export default contentPages
