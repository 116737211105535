// ** Initial State
const initialState = {
    allActivities: [],
    activitiesData: [],
    total: 1,
    totalItems: 0,
    params: {},
    selectedActivity: null,
    errorMessage: null,
    status: null, // 1 - fetching // 2 - success // 3 - error
    allActPets: [],
    actPetsErrorMessage: null,
    actPetStatus: null
  }
  
  const activities = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_ACTIVITIES':
        return { ...state, allActivities: action.data }
      case 'GET_ACTIVITIES':
        return {
          ...state,
          activitiesData: action.activitiesData,
          total: action.totalPages,
          totalItems: action.totalItems,
          params: action.params
      }
      case 'GET_ACTIVITY':
        return { ...state, selectedActivity: action.selectedActivity }
      case 'ADD_ACTIVITY':
        return { ...state }
      case 'UPDATE_ACTIVITY':
        return { ...state }
      case 'DELETE_ACTIVITY':
        return { ...state }
      case 'ACTIVITY_ERROR':
        return { ...state, errorMessage: action.error }
      case 'SET_ACTIVITY_STATUS':
        return { ...state, status: action.status }
      case 'GET_ALL_ACT_PETS':
        return { ...state, allActPets: action.pets }
      case 'ACT_PETS_ERROR':
        return { ...state, actPetsErrorMessage: action.petsError }
      case 'SET_ACT_PET_STATUS':
        return { ...state, actPetStatus: action.petsStatus }
      default:
        return { ...state }
    }
  }
  export default activities
  