// ** Initial State
const initialState = {
    notifications: [],
    categoriesList: [],
    selectedNotification: null,
    errorMessage: null,
    errorCat: null,
    total: 0,
    params: {},
    catStatus: null,
    status: null // 1 - fetching // 2 - success // 3 - error
  }
  
  const notifications = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_NOTIFICATIONS':
        return { ...state, notifications: action.notifications, total: action.total }
      case 'GET_NOTIFICATION':
        return { ...state, selectedNotification: action.notification }
      case 'ADD_NOTIFICATION':
        return { ...state }
      case 'UPDATE_NOTIFICATION':
        return { ...state }
      case 'DELETE_NOTIFICATION':
        return { ...state }
      case 'GET_CATEGORIES':
        return { ...state, categoriesList: action.categories }
      case 'ITEM_ERROR':
        return { ...state, errorMessage: action.error }
      case 'SET_ITEM_STATUS':
        return { ...state, status: action.status }
      case 'CAT_ERROR':
        return { ...state, errorCat: action.error }
      case 'SET_CAT_STATUS':
        return { ...state, catStatus: action.status }
      default:
        return { ...state }
    }
  }
  export default notifications
  