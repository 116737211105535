// ** Initial State
const initialState = {
    profileData: [],
    errorMessage: null,
    status: null, // 1 - fetching // 2 - success // 3 - error
    profileImageUrl: null
  }
  
  const profileData = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_PROFILE_DATA':
        return {
          ...state,
          profileData: action.data
      }
      case 'UPDATE_PROFILE_DATA':
        return { ...state, profileData: action.data }
      case 'PROFILE_DATA_ERROR':
        return { ...state, errorMessage: action.error }
      case 'PROFILE_DATA_STATUS':
        return { ...state, status: action.status }
      case 'UPLOAD_PROFILE_IMAGE':
        return { ...state, profileImageUrl: action.imageUrl }
      default:
        return { ...state }
    }
  }
  export default profileData
  