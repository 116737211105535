// ** Initial State
const initialState = {
    allData: [],
    detail: {},
    newStatus: {},
    status: null // 1 - fetching // 2 - success // 3 - error
  }
  
  const orderStatus = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_DATA':
        return { ...state, allData: action.data }
      case 'GET_DETAIL_ORDER_STATES':
        return { ...state, detail: action.data}
      case 'UPDATE_STATUS':
        return { ...state }
      case 'ADD_STATUS':
        return { ...state, newStatus: action.data?.data }
      case 'DELETE_STATUS':
        return { ...state }
      case 'ERROR':
        return { ...state, errorMessage: action.error }
      case 'SET_STATUS':
        return { ...state, status: action.status }
      default:
        return { ...state }
    }
  }
  export default orderStatus
  