// ** Initial State
const initialState = {
    allPets: [],
    petsData: [],
    total: 1,
    totalItems: 0,
    params: {},
    selectedPet: null,
    errorMessage: null,
    status: null, // 1 - fetching // 2 - success // 3 - error
    allPetDevices: [],
    deviceErrorMessage: null,
    deviceStatus: null,
    allPetBreeds: [],
    breedErrorMessage: null,
    breedStatus: null,
    petImageUrl: null
  }
  
  const pets = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_PETS':
        return { ...state, allPets: action.data }
      case 'GET_PETS':
        return { ...state, petsData: action.data, totalItems: action.totalItems }
      case 'GET_PET':
        return { ...state, selectedPet: action.selectedPet }
      case 'ADD_PET':
        return { ...state }
      case 'UPDATE_PET':
        return { ...state }
      case 'DELETE_PET':
        return { ...state }
      case 'PET_ERROR':
        return { ...state, errorMessage: action.error }
      case 'SET_PET_STATUS':
        return { ...state, status: action.status }
      case 'GET_ALL_DEVICES':
          return { ...state, allPetDevices: action.devices }
      case 'DEVICE_ERROR':
        return { ...state, deviceErrorMessage: action.deviceError }
      case 'SET_DEVICE_STATUS':
        return { ...state, deviceStatus: action.deviceStatus }
      case 'GET_ALL_BREEDS':
          return { ...state, allPetBreeds: action.breeds }
      case 'BREED_ERROR':
        return { ...state, breedErrorMessage: action.breedError }
      case 'SET_BREED_STATUS':
        return { ...state, breedStatus: action.breedStatus }
      case 'UPLOAD_PET_IMAGE':
        return { ...state, petImageUrl: action.imageUrl }
      default:
        return { ...state }
    }
  }
  export default pets
  