// ** Initial State
const initialState = {
  allUserSettings: [],
  userSettingsData: [],
  selectedUserSettings: null,
  total: 1,
  totalItems: 0,
  params: {},
  errorMessage: null,
  status: null // 1 - fetching // 2 - success // 3 - error
}

const userSettings = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_USER_SETTINGS':
      return { ...state, allUserSettings: action.allData }
    case 'GET_USER_SETTINGS':
      return {
        ...state,
        userSettingsData: action.data,
        total: action.totalPages,
        totalItems: action.totalItems,
        params: action.params
    }
    case 'GET_USER_SETTING':
      return { ...state, selectedUserSettings: action.selectedUserSetting }
    case 'DELETE_USER_SETTING':
      return { ...state }
    case 'USER_SETTINGS_ERROR':
      return { ...state, errorMessage: action.error }
    case 'SET_USER_SETTINGS_STATUS':
      return { ...state, status: action.status }
    default:
      return { ...state }
  }
}
export default userSettings
