// ** Initial State
const initialState = {
  allLocationTrackings: [],
  locationTrackingsData: [],
  total: 1,
  totalItems: 0,
  params: {},
  selectedLocationTracking: null,
  errorMessage: null,
  status: null, // 1 - fetching // 2 - success // 3 - error
  allLocPets: [],
  petsErrorMessage: null,
  locPetStatus: null,
  allLocActivities: [],
  activityErrorMessage: null,
  locActStatus: null
}

const locationTrackings = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_LOCATION_TRACKINGS':
      return { ...state, allLocationTrackings: action.data }
    case 'GET_LOCATION_TRACKINGS':
      return {
        ...state,
        locationTrackingsData: action.locationTrackingsData,
        total: action.totalPages,
        totalItems: action.totalItems,
        params: action.params
    }
    case 'GET_LOCATION_TRACKING':
      return { ...state, selectedLocationTracking: action.selectedLocationTracking }
    case 'ADD_LOCATION_TRACKING':
      return { ...state }
    case 'UPDATE_ACTIVITY':
      return { ...state }
    case 'DELETE_LOCATION_TRACKING':
      return { ...state }
    case 'LOCATION_TRACKING_ERROR':
      return { ...state, errorMessage: action.error }
    case 'SET_LOCATION_TRACKING_STATUS':
      return { ...state, status: action.status }
    case 'GET_ALL_LOC_PETS':
      return { ...state, allLocPets: action.pets }
    case 'LOC_PETS_ERROR':
      return { ...state, petsErrorMessage: action.petsError }
    case 'SET_LOC_PET_STATUS':
      return { ...state, locPetStatus: action.petsStatus }
    case 'GET_ALL_LOC_ACTIVITIES':
      return { ...state, allLocActivities: action.activities }
    case 'LOC_ACTIVITIES_ERROR':
      return { ...state, activityErrorMessage: action.activityError }
    case 'SET_LOC_ACTIVITY_STATUS':
      return { ...state, locActStatus: action.activitiesStatus }
    default:
      return { ...state }
  }
}
export default locationTrackings
