// ** Initial State
const initialState = {
    allPetHomes: [],
    petHomesData: [],
    total: 1,
    totalItems: 0,
    params: {},
    selectedPetHome: null,
    errorMessage: null,
    status: null, // 1 - fetching // 2 - success // 3 - error
    allPetHomePets: [],
    petHomePetsErrorMessage: null,
    petHomePetStatus: null
  }
  
  const petHomes = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_PET_HOMES':
        return { ...state, allPetHomes: action.data, totalItems: action.totalItems }
      case 'GET_PET_HOMES':
        return {
          ...state,
          petHomesData: action.petHomesData,
          total: action.totalPages,
          totalItems: action.totalItems,
          params: action.params
      }
      case 'GET_PET_HOME':
        return { ...state, selectedPetHome: action.selectedPetHome }
      case 'ADD_PET_HOME':
        return { ...state }
      case 'UPDATE_PET_HOME':
        return { ...state }
      case 'DELETE_PET_HOME':
        return { ...state }
      case 'PET_HOME_ERROR':
        return { ...state, errorMessage: action.error }
      case 'SET_PET_HOME_STATUS':
        return { ...state, status: action.status }
      case 'GET_ALL_PET_HOME_PETS':
        return { ...state, allPetHomePets: action.pets }
      case 'PET_HOME_PETS_ERROR':
        return { ...state, petHomePetsErrorMessage: action.petsError }
      case 'SET_PET_HOME_PET_STATUS':
        return { ...state, petHomePetStatus: action.petsStatus }
      default:
        return { ...state }
    }
  }
  export default petHomes
  