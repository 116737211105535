// ** Initial State
// Lb - leaderboard
const initialState = {
    allLb: [],
    lbData: [],
    total: 1,
    totalItems: 0,
    params: {},
    selectedLb: null,
    errorMessage: null,
    status: null, // 1 - fetching // 2 - success // 3 - error
    allLbPets: [],
    lbPetsErrorMessage: null,
    lbPetStatus: null
  }
  
  const leaderboards = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_LB':
        return { ...state, allLb: action.data, totalItems: action.totalItems }
      case 'GET_LBS':
        return {
          ...state,
          lbData: action.data,
          total: action.totalPages,
          totalItems: action.totalItems,
          params: action.params
      }
      case 'GET_LB':
        return { ...state, selectedLb: action.selected }
      case 'ADD_LB':
        return { ...state }
      case 'UPDATE_LB':
        return { ...state }
      case 'DELETE_LB':
        return { ...state }
      case 'LB_ERROR':
        return { ...state, errorMessage: action.error }
      case 'SET_LB_STATUS':
        return { ...state, status: action.status }
      case 'GET_ALL_LB_PETS':
        return { ...state, allLbPets: action.pets }
      case 'LB_PETS_ERROR':
        return { ...state, lbPetsErrorMessage: action.petsError }
      case 'SET_LB_PET_STATUS':
        return { ...state, lbPetStatus: action.petsStatus }
      default:
        return { ...state }
    }
  }
  export default leaderboards
  