// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import profileData from '../../views/Account/store/reducer'
import navbar from './navbar'
import layout from './layout'
import users from '../../views/Users/store/reducer'
import locationTrackings from '../../views/Locations/store/reducer'
import petHealth from '../../views/PetHealth/store/reducer'
import devices from '../../views/Devices/store/reducer'
import pets from '../../views/Pets/store/reducer'
import activities from '../../views/Activities/store/reducer'
import petHomes from '../../views/PetHomes/store/reducer'
import leaderboards from '../../views/Leaderboards/store/reducer'
import petBreed from '../../views/PetBreed/store/reducer'
import deviceVersions from '../../views/DeviceVersions/store/reducer'
import userSettings from '../../views/UserSettings/store/reducer'
import promotions from '../../views/Promotions/store/reducer'
import cms from '../../views/CMS/store/reducer'
import notifications from '../../views/Notifications/store/reducer'
import contentPages from '../../views/ContentPages/store/reducer'
import nutritions from '../../views/Nutritions/store/reducer'
import currencies from '../../views/Currency/store/reducer'
import orderStatus from '../../views/OrderStatus/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  users,
  locationTrackings,
  petHealth,
  devices,
  pets,
  activities,
  petHomes,
  leaderboards,
  petBreed,
  deviceVersions,
  userSettings,
  promotions,
  profileData,
  cms,
  notifications,
  contentPages,
  nutritions,
  currencies,
  orderStatus
})

export default rootReducer
