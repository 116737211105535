// ** Initial State
const initialState = {
    allData: [],
    errorMessage: null,
    data: [],
    total: 1,
    params: {},
    selectedUser: null,
    status: null // 1 - fetching // 2 - success // 3 - error
  }
  
  const users = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_DATA':
        return { ...state, allData: action.data }
      case 'GET_DATA':
        return {
          ...state,
          data: action.data,
          total: action.totalItems,
          params: action.params
      }
      case 'GET_USER':
        return { ...state, selectedUser: action.selectedUser }
      case 'ADD_USER':
        return { ...state }
      case 'UPDATE_USER':
        return { ...state }
      case 'DELETE_USER':
        return { ...state }
      case 'USER_ERROR':
        return { ...state, errorMessage: action.error }
      case 'SET_USER_STATUS':
        return { ...state, status: action.status }
      default:
        return { ...state }
    }
  }
  export default users
  