// ** Initial State
const initialState = {
    allPetHealth: [],
    petHealthData: [],
    total: 1,
    totalItems: 0,
    params: {},
    selectedPetHealth: null,
    errorMessage: null,
    status: null, // 1 - fetching // 2 - success // 3 - error
    allPthPets: [],
    petsErrorMessage: null,
    pthPetStatus: null,
    allPthActivities: [],
    activityErrorMessage: null,
    pthActStatus: null
}
  
  const petHealthTrackings = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_PET_HEALTH':
        return { ...state, allPetHealth: action.data }
      case 'GET_PET_HEALTHS':
        return {
          ...state,
          petHealthData: action.petHealthData,
          total: action.totalPages,
          totalItems: action.totalItems,
          params: action.params
      }
      case 'GET_PET_HEALTH':
        return { ...state, selectedPetHealth: action.selectedPetHealth }
      case 'ADD_PET_HEALTH':
        return { ...state }
      case 'UPDATE_PET_HEALTH':
        return { ...state }
      case 'DELETE_PET_HEALTH':
        return { ...state }
      case 'PET_HEALTH_ERROR':
        return { ...state, errorMessage: action.error }
      case 'SET_PET_HEALTH_STATUS':
        return { ...state, status: action.status }
      case 'GET_ALL_PTH_PETS':
        return { ...state, allPthPets: action.pets }
      case 'PTH_PETS_ERROR':
        return { ...state, petsErrorMessage: action.petsError }
      case 'SET_PTH_PET_STATUS':
        return { ...state, pthPetStatus: action.petsStatus }
      case 'GET_ALL_PTH_ACTIVITIES':
        return { ...state, allPthActivities: action.activities }
      case 'PTH_ACTIVITIES_ERROR':
        return { ...state, activityErrorMessage: action.activityError }
      case 'SET_PTH_ACTIVITY_STATUS':
        return { ...state, pthActStatus: action.activitiesStatus }
      default:
        return { ...state }
    }
  }
  export default petHealthTrackings
  